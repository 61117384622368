@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif !important;
}

label {
  font-family: "DM Sans", sans-serif !important;
}

p {
  font-family: "DM Sans", sans-serif !important;
}

a {
  font-family: "DM Sans", sans-serif !important;
}

button {
  font-family: "DM Sans", sans-serif !important;
}

tr {
  font-family: "DM Sans", sans-serif !important;
}

td {
  font-family: "DM Sans", sans-serif !important;
}

th {
  font-family: "DM Sans", sans-serif !important;
}

h1 {
  font-family: "DM Sans", sans-serif !important;
}

h2 {
  font-family: "DM Sans", sans-serif !important;
}

h3 {
  font-family: "DM Sans", sans-serif !important;
}

h4 {
  font-family: "DM Sans", sans-serif !important;
}

h5 {
  font-family: "DM Sans", sans-serif !important;
}

h6 {
  font-family: "DM Sans", sans-serif !important;
}

span {
  font-family: "DM Sans", sans-serif !important;
}

th {
  font-family: "DM Sans", sans-serif !important;
}

tr {
  font-family: "DM Sans", sans-serif !important;
}

tspan {
  font-family: "DM Sans", sans-serif !important;
}

div {
  font-family: "DM Sans", sans-serif !important;
}

select {
  font-family: "DM Sans", sans-serif !important;
}

option {
  font-family: "DM Sans", sans-serif !important;
}

input {
  font-family: "DM Sans", sans-serif !important;
}

.ant-input {
  font-family: "DM Sans", sans-serif !important;
}

option {
  color: black;
}

.termsCheck {
  display: ruby !important;
}

.inputs {
  /*height: 48px;*/
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
}

.inputs-error-select {
  width: 100%;
}

.inputs-error-select .ant-select-selector {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red !important;
}

.inputs-error-select .ant-select-selector:hover {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red !important;
}

.inputs-error-select .ant-select-selector:focus {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red !important;
}

.inputs-error-select .ant-select-selector ::selection {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red !important;
}

.inputs-error {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red;
}

.inputs-error:hover {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red;
}

.inputs-error:focus {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red;
}

.inputs-error ::selection {
  /*height: 48px;*/
  max-height: 100%;
  /* margin-left: 70px; */
  /* width: 100%; */
  display: flex;
  border: 1px solid red;
}

.error-text {
  color: red;
  text-align: left;
  font-size: 12px;
}

.formLabel {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #777;
  margin: 10px 0px 7px 0px;
  /* width: 20%; */

  display: block;
  border: 1px dot #777;
}

/*Payment Page*/
.main_container_card {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
}

.align_rights_items {
  text-align: end;
}

.header_payment_page_part {
  padding: 15px;
  padding-top: 12px;
  padding-bottom: 0px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.endline_payment {
  margin-left: 15px;
  margin-right: 15px;
}

.payment-btn_pay {
  margin-bottom: 8px;
}

.add-new-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  font-weight: bold;
  color: #0981c8;
}

.bank-account-card-row {
  display: flex;
  cursor: pointer;
  overflow-x: auto;
}

.bank-account-card-new {
  min-width: 270px;
  border: 1px solid #0981c8;
  background-color: #f6f6f6;
  /*background-image: linear-gradient(315deg,
   #00B7FF 0%, #00B5FF 94%);*/
  border-radius: 25px;
  padding: 20px;
  margin-right: 10px;
  min-height: 245px;
  /*background-color: #F8F8F8;*/
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /*overflow-y: scroll;*/
}

.dashboard-container .requests td .anticon {
  vertical-align: middle;
}

.dashboard-container .requests .paginator {
  margin: 15px;
  text-align: right;
}

.payreq-container {
  padding-top: 10px;
}

.payreq-box {
  width: 350px;
  margin: 0px auto;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.payreq-box .alert {
  padding: 15px;
  font-size: 14px;
  color: rgb(206, 72, 72);
}

.payreq-box .content {
  padding: 25px;
  padding-top: 0px;
}

.payreq-box .decor-line {
  height: 3px;
  opacity: 0.5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #47a1b0;
  /*background: linear-gradient(45deg, #1FAE8F 0%,#47B6EA 100%);*/
}

.payreq-container .btn-container {
  width: 20%;
  min-width: 340px;
  margin: 16px auto 30px auto;
  display: block;
}

.payreq-container .alert {
  margin: 0 auto;
  text-align: center;
}

.payreq-box input[name="auth_nonce"] {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: center;
  font-family: "DM Sans", sans-serif !important;
}

.paymentRequestContent {
  padding: 10px 20px;
}

@media only screen and (max-width: 700px) {
  .paymentRequestContent {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding: 20px;
    width: 100%;
  }
}

.payreq-container {
  padding-top: 10px;
}

.payreq-box {
  width: 350px;
  margin: 0px auto;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.payreq-box .alert {
  padding: 15px;
  font-size: 14px;
  color: rgb(206, 72, 72);
}

.payreq-box .content {
  padding: 25px;
  padding-top: 0px;
}

.modalButton {
  display: flex;
  justify-content: end;
  margin-top: 25px;
  padding: 5px;
}

.paymentRequestContent {
  background-color: #ffffff;
  border-radius: 5px;
}

@media only screen and (max-width: 700px) {
  .paymentRequestContent {
    background-color: #ffffff;
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
  }
}

.paymentRequestContent .content {
  max-width: 390px;
  min-width: 40px;
  margin: 0 auto;
}

.paymentRequestContent .attention input,
.paymentRequestContent .attention {
  border-color: red;
}

.paymentRequestContent .input-alert {
  color: red;
  width: 20%;
  min-width: 340px;
  margin: 5px auto;
  margin-top: 0;
  font-size: 13px;
}

.css-17psu2f {
  margin-left: 8px;
}

.createBtn {
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 183, 255, 0.4);
  background-color: #1677ff;
  border: none;
  color: #ffffff;
  width: 200px;
  box-sizing: unset;
  float: right;
}

.whiteBtn {
  width: 120px;
  box-sizing: unset;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 183, 255, 0.4);
  border: solid 1px #1677ff;
  background-color: #ffffff;
  margin-right: 10px;
}

.menuItemHaveSubMenu {
  cursor: pointer;
}

.subMenu {
  background-color: rgba(210, 210, 210, 0.18);
  /* Set the background color to white with 50% opacity */
  padding: 0px;
  /* Add 10 pixels of padding */
  border-radius: 20px;
  cursor: pointer;
  padding-left: 15px;
}

.cardHeadModal {
  color: #ccc;
  font-size: 13px;
  margin-top: 15px;
}

.cardHeadModal {
  color: #ccc;
  font-size: 13px;
}

.cardHeadLeft {
  text-align: left;
}

.cardHeadRight {
  text-align: right;
}

.cardContext {
  color: #7a08fa;
  /*font-weight: bold;*/
  font-size: 16px;
}

.cardContextModal {
  color: #000;
  /*font-weight: bold;*/
  font-size: 16px;
}

.btnBox {
  margin-top: 15px;
}

.ant-form-item {
  box-sizing: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "DM Sans", sans-serif !important;
  margin-bottom: 0px;
  vertical-align: top;
}

/* payment method */
.payment-page {
  margin: 5% 25%;
}

@media (max-width: 768px) {
  .payment-page {
    margin: 5% 10%;
  }
}

@media (max-width: 480px) {
  .payment-page {
    margin: 15% 1%;
  }
}

.ant-steps.ant-steps-vertical {
  flex-direction: row !important;
}

/* Chakra ui Change colors*/
:root {
  --chakra-colors-brand-100: #e9e3ff !important;
  --chakra-colors-brand-200: #00b7ff !important;
  --chakra-colors-brand-300: #0073ff !important;
  --chakra-colors-brand-400: #0059ff !important;
  --chakra-colors-brand-500: #00b7ff !important;
  --chakra-colors-brand-600: #0981c8 !important;
  --chakra-colors-brand-700: #0981c8 !important;
  --chakra-colors-brand-800: #0981c8 !important;
  --chakra-colors-brand-900: #11047a !important;
  --chakra-colors-brandScheme-100: #e9e3ff !important;
  --chakra-colors-brandScheme-200: #00b7ff !important;
  --chakra-colors-brandScheme-300: #0073ff !important;
  --chakra-colors-brandScheme-400: #0059ff !important;
  --chakra-colors-brandScheme-500: #00b7ff !important;
  --chakra-colors-brandScheme-600: #0981c8 !important;
  --chakra-colors-brandScheme-700: #0981c8 !important;
  --chakra-colors-brandScheme-800: #0981c8 !important;
  --chakra-colors-brandScheme-900: #09090a !important;
  --chakra-colors-brandTabs-100: #e9e3ff !important;
  --chakra-colors-brandTabs-200: #00b7ff !important;
  --chakra-colors-brandTabs-300: #0073ff !important;
  --chakra-colors-brandTabs-400: #0059ff !important;
  --chakra-colors-brandTabs-500: #00b7ff !important;
  --chakra-colors-brandTabs-600: #0981c8 !important;
  --chakra-colors-brandTabs-700: #0981c8 !important;
  --chakra-colors-brandTabs-800: #0981c8 !important;
  --chakra-colors-brandTabs-900: #09090a !important;
  --chakra-colors-navy-50: #d0dcfb !important;
  --chakra-colors-navy-100: #aac0fe !important;
  --chakra-colors-navy-200: #a3b9f8 !important;
  --chakra-colors-navy-300: #728fea !important;
  --chakra-colors-navy-400: #000dff !important;
  --chakra-colors-navy-500: #00b7ff !important;
  --chakra-colors-navy-600: #0981c8 !important;
  --chakra-colors-navy-700: #0981c8 !important;
  --chakra-colors-navy-800: #0981c8 !important;
  --chakra-colors-navy-900: #0b1437 !important;
}

/* in mobile view sidebar */
.css-5epui9 {
  width: auto !important;
}

.blodText {
  font-weight: bold;
  color: rgb(9, 129, 200);
  font-size: 20px;
  cursor: pointer;
}

.blodText:hover {
  text-decoration: underline;
}

.FormWrapperModal
  .ant-col.ant-col-24.ant-form-item-label.css-dev-only-do-not-override-k7429z {
  padding: 0px !important;
}

.cancel_subscruption {
  cursor: pointer;
  color: red;
}

.resend_subscruption {
  cursor: pointer;
  color: orange;
}

.cancel_subscruption:hover {
  text-decoration: underline;
}
.resend_subscruption:hover {
  text-decoration: underline;
}

:where(.css-dev-only-do-not-override-k7429z).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.notification-counter {
  font-size: 12px;
  color: white;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 3px;
  right: 70px;
}

.label-notif {
  color: #999;
}

@media (max-width: 768px) {
  .notification-counter {
    display: none;
  }
}

custom-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Create custom scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

/* Create custom scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 4px;
}

/* Add hover effect to custom scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}

/* Set width of custom scrollbar track */
.custom-scrollbar {
  -ms-overflow-style: none;
  /* For Internet Explorer, Edge */
  scrollbar-width: thin;
  /* For Firefox */
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    /* Adjust width as needed */
  }
}

/* chakra-like.css */

/* Button */
.ant-btn {
  border: none;
  border-radius: 8px;
  /* padding: 8px 16px; */
  font-size: 14px;
  font-weight: 500;
}

/* Input */
.ant-input {
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
}

/* DatePicker */
.ant-picker {
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
}

/* Select */
.ant-select-selector {
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
}

/* Table */
.ant-table {
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

/* Card */
.ant-card {
  border-radius: 8px;
}

.ant-card-body {
  padding: 16px;
}

/* Form */
.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-label > label {
  font-weight: 600;
}

.ant-form-item-control-input {
  margin-top: 4px;
}

/* Form Input */
.ant-form-item-control-input-content .ant-input {
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
}

/* chakra-like.css */

/* InputNumber */
.ant-input-number {
  border-radius: 8px;
  padding: 5px 12px;
  font-size: 16px;
  width: 100%;
}

.ant-input-number-handler-wrap {
  display: none; /* Hides the default handlers to simplify the design */
}

/* Select */
.ant-select-selector {
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
}

/* Select Dropdown */
.ant-select-dropdown {
  border-radius: 8px;
}

.ant-select-item {
  padding: 8px 12px;
  font-size: 16px;
}

.formLabel {
  color: #2d3748; /* Chakra UI gray.700 */
  font-weight: 500;
  font-size: 14px; /* Match input and select font size */
  margin-bottom: 8px; /* Adjust margin bottom for spacing */
  margin-top: 8px; /* Adjust margin top for spacing */
}

/* Select */
.ant-select-selector {
  border-radius: 8px !important;
  padding: 8px 12px !important;
  font-size: 16px !important;
  min-height: 40px;
  margin-bottom: 15px !important;
}

/* Select Dropdown */
.ant-select-dropdown {
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.ant-select-item {
  padding: 8px 12p !important;
  font-size: 16px !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-form-item-label {
  padding: 0 0 2px !important;
}
/* Default styles (for screens larger than 470px) */
.responsive-radio-group .responsive-radio-item {
  display: inline-block; /* Normal inline-block for large screens */
  width: auto;
  margin-bottom: 0;
}

.responsive-radio-group .ant-radio-button-wrapper {
  width: auto; /* Default button width */
}

/* For screens smaller than or equal to 470px */
@media (max-width: 470px) {
  .responsive-radio-group .responsive-radio-item {
    display: block; /* Stack items vertically */
    width: 100%; /* Make the container div full width */
    margin-bottom: 8px; /* Space between items */
  }

  .responsive-radio-group .ant-radio-button-wrapper {
    width: 100%; /* Ensure buttons inside the div are full width */
    text-align: center; /* Center the button text */
  }

  .responsive-radio-group .responsive-radio-item:last-child {
    margin-bottom: 0; /* Remove margin for the last item */
  }
}

.ant-row .ant-divider-vertical{
  height: inherit !important;
}
